import * as React from "react"
import Helmet from "react-helmet"
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import Layout from "../components/layout"
import HeroHome from "../components/heroHome";
import IntroHome from "../components/introHome";
import ExitoHome from "../components/exitoHome";
import CtaCotizador from  "../components/ctaCotizadorServicio";
import Contacto from '../components/contacto';
import BtnWa from '../components/btnWa';


export default function Index({ data: { queryHome, site } }) {
  
  const homeData = queryHome.edges[0].node;
  console.log(homeData.clientes);

  return (
      <Layout>
        <Helmet>
          <meta name="facebook-domain-verification" content="vhaf1yaw798jd39gcjbv7d04nx90tu" />
        </Helmet>
        <HelmetDatoCms 
          seo={homeData.seoMetaTags}
          favicon={site.favicon}
        />
        <HeroHome
          slider={homeData.slider}
        />        
        <IntroHome
          titulo={homeData.titulo}
          introduccion={homeData.introduccion}
          servicios={homeData.servicios}
          imagendescripcion={homeData.imagendescripcion}
        />
        <ExitoHome
          video1={homeData.video1}
          imagen1={homeData.imagen1}
          titulo1={homeData.titulo1}
          descripcion1={homeData.descripcion1}
          
          video2={homeData.video2}
          imagen2={homeData.imagen2}
          titulo2={homeData.titulo2}
          descripcion2={homeData.descripcion2}
          
          video3={homeData.video3}
          imagen3={homeData.imagen3}
          titulo3={homeData.titulo3}
          descripcion3={homeData.descripcion3}     
          
          clientes={homeData.clientes}
        />
        <CtaCotizador
            cta={homeData.cta}
            servicio='principal'
        />
        <BtnWa/>
        <Contacto/>
      </Layout>
      
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    queryHome: allDatoCmsHome(filter: {slug: {eq: "home"}}) {
      edges {
        node {
          slider{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 1500px) 100vw, 1500px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
                title
                alt
          }
          titulo {
            value
          }
          introduccion{
            value
          }
          servicios{
            value
          }
          imagendescripcion{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 1500px) 100vw, 1500px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
          }
          video1
          imagen1{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 1500px) 100vw, 1500px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
                url
          }
          titulo1{
            value
          }
          descripcion1{
            value
          }
          video2
          imagen2{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 1500px) 100vw, 1500px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
                url
          }   
          titulo2{
            value
          }   
          descripcion2{
            value
          }
          video3
          imagen3{
            fluid(
                  imgixParams: { fm: "jpg" }
                  sizes: "(max-width: 1500px) 100vw, 1500px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
                url
          }  
          titulo3{
            value
          }  
          descripcion3{
            value
          }
          clientes{
            fluid(
                  imgixParams: { fm: "png" }
                  sizes: "(max-width: 1500px) 100vw, 1500px"
                ) {
                  ...GatsbyDatoCmsFluid
                }
                title
                alt
          }          
          cta{
            value
          }   
          seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
        }
      }
    }

  }
`;