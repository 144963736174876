import React from 'react';
import Image from 'gatsby-image'
import styled from 'styled-components'
import TextStructured from './textStructured'


const SectionStyle = styled.section`
    padding: 2em 1em; 
    @media (min-width: 1024px) {
        max-width: 1620px;
        margin: 0 auto;
        padding: 6em 4em;
        p{
            font-size: 1.4em;
        }
    }
`;

const ServiciosStyle = styled.div`
    margin: 0 auto;
    background-color: #f6f6f6;
    padding: 2em 1em; 
    display: grid;
    gap: 2em;
    a{
        &:hover{
            color: #77b843;
            transition-duration: 1s;
        }
    }
    img{        
        border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
        border:solid 2px #f6f6f6;
        transition-duration: 1s;
    }
    hr{
        width: 100%; 
        height: 4px;
        background: rgb(255,255,255);
        background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(217,217,217,0.923406862745098) 48%, rgba(119,184,67,1) 100%);
    }
    @media (min-width: 1024px) {
        max-width: 1620px;
        padding: 5em 4em;
        grid-template-columns: 35% 60%;
        align-items: center;
        gap: 5%;   
    }
`;



const IntroHome = ({titulo, introduccion, servicios, imagendescripcion}) => {
    return ( 
        <>
            <SectionStyle>
                <TextStructured content={introduccion} />
            </SectionStyle>
            <ServiciosStyle>
                <div>
                    <Image fluid={imagendescripcion.fluid} alt={titulo}/>
                </div>
                <div>
                    <h2>¿Qué hacemos?</h2>
                    <br></br><br></br>
                    <TextStructured content={servicios} />
                </div>
            </ServiciosStyle>
        </>

     );
}
 
export default IntroHome;