import React from 'react';
import styled from 'styled-components'

import { InputStyle, TextareaStyle, SendStyle} from '../ui'

//Clave sitio 6LdYq3UeAAAAAGCZRaA6bN_cN8xScoabAP8aj1B9
//Clave secreta 6LdYq3UeAAAAAFKN68m03axgBcgmcWKZmIqTdMf7


const FormStyle = styled.form`
    display: grid;
    gap: .8em;
`;

const FormContacto = () => {

    const currentURL = 'utm'    

    return ( 
        <FormStyle
            method="post" 
            action="https://somosveneno.com/ventas/contacto.php"
        >            
            <InputStyle
                type='text'
                id="nombre"
                name="nombre"
                placeholder="Nombre*"
                required
            />
            <InputStyle
                type='email'
                placeholder='Email*'
                id="email"
                name="email"
                required
            />
            <InputStyle
                type='phone'
                placeholder='Teléfono*'
                id="tel"
                name="tel"
                required
            />   
            <InputStyle
                type='text'
                placeholder='Particular o empresa'
                id="tipo"
                name="tipo"
            />  
            <TextareaStyle
                type='text'
                placeholder='Mensaje'
                id="mensaje"
                name="mensaje"
                rows={3}
            />      
            <InputStyle
                type='text'                    
                id="utm"
                name="utm"
                value={currentURL}
                disabled
                style={{ display:'none' }}
            />        
            <SendStyle
                type='submit'
                value='Enviar'
            />  
            
        </FormStyle>
     );
}
 
export default FormContacto;