import React from 'react';
import { Link } from "gatsby"
import styled from 'styled-components'
import FormContacto from './forms/formContacto'

const SectionStyle = styled.section`
    padding: 2em 0; 
    @media (min-width: 1024px) {
        max-width: 1620px;
        margin: 0 auto;
        padding: 2em 4em 6em 4em;
    }
`;

const GridStyle = styled.div`
    display: grid;
    @media (min-width: 1024px) {
        grid-template-columns: 40% 60%;
        align-items:center;
    }
`;

const TextStyle = styled.div`
    background-color: #f6f6f6;
    padding: 2em 1em;
    display: grid;
    gap: 1em;
    span{
        color: #000000;
    }
    a{
        &:hover{
            color: #77b843;
        }
    }
    @media (min-width: 1024px) {
        padding: 2em 4em;
    }
`;

const MapaStyle = styled.div`
    height: 20em;
    @media (min-width: 1024px) {
        height: 30em;
    }
`;



const Contacto = () => {
    return ( 
        <SectionStyle id="contacto">
            <GridStyle>
                <TextStyle>
                    <h2>Contacto</h2>
                    <address>Adolfo Prieto 823, Colonia del Valle, CP 03100, CDMX</address>
                    <div>                
                        <Link
                            to='mailto:info@inteligenciasolar.com.mx' target='_blank'                   
                        > info@inteligenciasolar.com.mx
                        </Link>
                    </div>
                    <div>
                        <Link
                            to='tel:5563830657' target='_blank' 
                        > (55) 6383 0657
                        </Link>, 
                        <Link
                            to='tel:5533300008' target='_blank' 
                        > (55) 3330 0008
                        </Link>
                    </div>
                    <FormContacto/>
                </TextStyle>
                <MapaStyle>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.5362079948886!2d-99.17061808454675!3d19.389224747089155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff0ecebbd889%3A0x56bee23159d28ed7!2sINTELIGENCIA%20SOLAR%20MEXICO%20SA%20DE%20CV!5e0!3m2!1ses-419!2smx!4v1643833514620!5m2!1ses-419!2smx" 
                        width="100%" 
                        height="100%" 
                        frameborder="0"
                        title='Inteligencia Solar'
                        >
                    </iframe>
                </MapaStyle>
            </GridStyle>
        </SectionStyle>
     );
}
 
export default Contacto;