import React from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeroBanner from './heroBanner';

const HeroHome = ({slider}) => {
    return ( 
        <Carousel autoPlay showThumbs={false} showArrows={false} showStatus={false} infiniteLoop={true} interval={9000}>
            {slider.map(data => (
                <HeroBanner
                    key={data.id}
                    data={data}                    
                />
            ))} 

        </Carousel>
     );
}
 
export default HeroHome;