import React from 'react';
import Image from 'gatsby-image'
import styled from 'styled-components'
import TextStructured from './textStructured'
import ClientePreview from './clientePreview'
import Video from './utils/video'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SectionStyle = styled.section`
    padding: 2em 1em 0 1em; 
    @media (min-width: 1024px) {
        max-width: 1620px;
        margin: 0 auto;
        padding: 8em 4em 1em 4em;
    }
    position: relative;
`;

const GridStyle = styled.div`
    padding: 1em 0 1em 0;
    display: grid;
    gap: 2em;
    @media (min-width: 1024px) {
        padding: 1em 0 2em 0;
        grid-template-columns: repeat(3,1fr);
        gap: 3em;
    }
`;

const CasoStyle = styled.div`
    display: grid;
    gap: 0;
    align-items: center;
`;

const DataStyle = styled.div`
    border: 4px solid #f6f6f6;
    padding: 2em 2em 1em 2em;
`;

const SliderStyle = styled.div`    
    padding-bottom: 2em;    
    width: 85%;
    margin: 0 auto 2em auto;
    text-align: center;
    @media (min-width: 769px) {
        background-color: #f6f6f6;
        max-width: 1620px;
        margin: 0 auto 3em auto;
        padding: 0em 4em;
        width: 100%;
    }    
`;


const ExitoHome = ({video1, imagen1, titulo1, descripcion1, video2, imagen2, titulo2, descripcion2, video3, imagen3, titulo3, descripcion3, clientes }) => {

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };

    return ( 
        <>
            <SectionStyle>
                <h2>Casos de éxito</h2>
                <GridStyle>
                    <CasoStyle>
                        {
                            video1 ? <Video urlvideo={imagen1.url} /> : <Image fluid={imagen1.fluid} alt="Caso de éxito Intelifencia Solar" />
                        }                                       
                        <DataStyle>
                            <TextStructured content={titulo1} />   
                            <TextStructured content={descripcion1} />   
                        </DataStyle>
                    </CasoStyle>
                    <CasoStyle>
                        {
                            video2 ? <Video urlvideo={imagen2.url} /> : <Image fluid={imagen2.fluid} alt="Caso de éxito Intelifencia Solar" />
                        }
                        <DataStyle>
                            <TextStructured content={titulo2} />   
                            <TextStructured content={descripcion2} />   
                        </DataStyle>
                    </CasoStyle>                
                    <CasoStyle>
                        {
                            video3 ?  <Video urlvideo={imagen3.url} /> : <Image fluid={imagen3.fluid} alt="Caso de éxito Intelifencia Solar" />
                        }
                        <DataStyle>
                            <TextStructured content={titulo3} />   
                            <TextStructured content={descripcion3} />   
                        </DataStyle>
                    </CasoStyle>                                             
                </GridStyle>
            </SectionStyle>            
            <SliderStyle>
                <Slider {...settings}>
                    {clientes.map(data => (
                        <ClientePreview
                            key={data.id}
                            data={data}                    
                        />
                    ))} 
                </Slider>
            </SliderStyle>
        </>
     );
}
 
export default ExitoHome;