import React from 'react';
import { Link } from "gatsby"
import styled from 'styled-components'
import Image from 'gatsby-image'
import { StaticImage } from "gatsby-plugin-image"
import {BtnIntro} from './ui'

/*

const ContentStyle = styled.div`
    padding: 0; 
    text-align: left;
    height: 50vh;  
    @media (min-width: 1024px) {
        max-width: 1420px;
        margin: 0 auto;
        height: 82vh;   
    }    
`;

const DataStyle = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    left:0;
    margin: 0 auto;
    padding: 5px 10px 2em 10px; 
    background-color: rgba(255, 255, 255, .8); 
    display: grid;
    gap: 1em;
    h2{
        text-transform: uppercase;
        font-size: 1.8rem;
        @media (min-width: 769px) {
            font-size: 3rem;
        }
    }    
    @media (min-width: 1024px) {
        padding: 1em 2em; 
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(255, 255, 255, .9); 
    }
`;

 */

const BackStyle = styled.div`    
    background-color: #f6f6f6;  
`;

const HeroStyle = styled.div`  
    padding: 1em 1em;      
    @media (min-width: 1024px) {
        max-width: 1620px;
        margin: 0 auto;
        padding: 8em 4em 8em 4em;

        display: grid;
        grid-template-columns: 35% 60%;
        gap: 5%;
        align-items: center;
        text-align: left;      
    }
`;

const TextStyle = styled.div`
    display: grid;
    gap: 1em;
    h2{
        text-transform: uppercase;
        font-size: 1.8rem;
        @media (min-width: 769px) {
            font-size: 3rem;
            font-weight: 600;
        }
    }  
    @media (max-width: 1024px) {
        padding: 1em 0em 2em 0em;
    }
`;

const ImgStyle = styled(Image)`
    border-radius: 4px;
    transition-duration: 1s;
    box-shadow: rgba(119, 184, 67, 0.4) 10px 10px, rgba(119, 184, 67, 0.3) 15px 15px, rgba(119, 184, 67, 0.2) 20px 20px;

`;

const RedesStyle = styled.div`
    @media (max-width: 1024px) {
        display:none;
    }
`;

const HeroBanner = ({data}) => {
    //console.log(data);
    return (  
        <section>
            {/* <BackgroundImage tag="section" fluid={data.fluid}>*/}
            <BackStyle>
                <HeroStyle>
                    <TextStyle>
                        <h2>{data.title}</h2>
                        <p>11 años de experiencia, ofreciendo estrategias personalizadas.</p>
                        <Link
                            to={data.alt}                    
                        >
                            <BtnIntro>
                                Conoce más
                            </BtnIntro>
                        </Link>                        
                        <RedesStyle style={{paddingTop : '20px'}}>
                            <Link
                                to='https://www.instagram.com/inteligenciasolar/'  target='blank'                  
                            >
                                <StaticImage
                                    src="../images/instagram.png"
                                    width={40}
                                    quality={100}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Menú"
                                    style={{ marginLeft: '10px'}}
                                />  
                            </Link>
                            <Link
                                to='https://www.facebook.com/inteligenciasolar/' target='blank'                    
                            >
                                <StaticImage
                                    src="../images/facebook.png"
                                    width={40}
                                    quality={100}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="Menú"
                                    style={{ marginLeft: '10px'}}
                                /> 
                            </Link>                                                         
                        </RedesStyle>
                    </TextStyle>
                    <ImgStyle fluid={data.fluid} alt={data.title}/>
                </HeroStyle>
            </BackStyle>
        </section>
    );
}
 
export default HeroBanner;