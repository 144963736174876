import React from 'react';
import Image from 'gatsby-image'
import styled from 'styled-components'

const CasoStyle = styled.div`    
    padding: 1em;
`;


const ClientePreview = ({data}) => {
    return (
        <CasoStyle>
          <Image fluid={data.fluid} alt="Cliente Inteligencia Solar" />
        </CasoStyle>
      );
}
 
export default ClientePreview;